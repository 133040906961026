import request from "./base/request";

export const createIdeaDbSource = (data) => {
    return request
      .post(`/db-source`, data)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };