<template>
  <div>
    <div class="listMenu-page padding-top-layout padding-right-layout list-menu">
      <div class="list-menu-title">
        <page-title :title="$t('idea_db_source.title_create_db_source')" />
      </div>
      <p class="list-menu--title">
        {{ $t('idea_db_source.label_db_source_description') }}
        <br />
        {{ $t('idea_db_source.label_db_source_sub_description') }}
      </p>
      <div class="listMenus-btn">
        <v-row no-gutters>
          <v-col cols="12">
            <div class="my-3">
              <div class="form-name">{{ $t('idea_db_source.label_form_title') }}</div>
            </div>
          </v-col>
          <v-col cols="12">
            <div class="my-3">
              <div class="title-btn title-btn-select-scope">
                <span>{{ $t('idea_db_source.label_db_name') }} </span>
                <div class="title-require">{{ $t('list_menu.label_essential') }}</div>
              </div>
              <div class="listMenu-btn-text">
                <input-field
                  :label="$t('idea_db_source.placeholder_db_name')"
                  class="mt-2"
                  v-model="formData.db_name"
                />
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="6">
            <div class="my-3 mr-md-2">
              <div class="title-btn title-btn-select-scope">
                <span>{{ $t('idea_db_source.label_db_version') }}</span>
                <div class="title-require">{{ $t('list_menu.label_essential') }}</div>
              </div>
              <div class="listMenu-btn-text">
                <input-field
                  :label="$t('idea_db_source.placeholder_db_version')"
                  class="mt-2"
                  v-model="formData.version"
                />
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="6">
            <div class="my-3 ml-md-2">
              <div class="title-btn title-btn-select-scope">
                <span>{{ $t('idea_db_source.label_db_identification_id') }}</span>
                <div class="title-require">{{ $t('list_menu.label_essential') }}</div>
              </div>
              <div class="listMenu-btn-text">
                <input-field
                  :label="$t('idea_db_source.placeholder_db_identification_id')"
                  class="mt-2"
                  v-model="formData.identification_id"
                />
              </div>
            </div>
          </v-col>

          <v-col cols="12">
            <div class="my-3">
              <div class="title-btn title-btn-select-scope">
                <span>{{ $t('idea_db_source.label_db_date_of_expiry') }}</span>
                <div class="title-require">{{ $t('list_menu.label_essential') }}</div>
              </div>
              <div class="contractor-expire listMenu-btn-text mt-2">
                <div class="time-selection">
                  <div class="custom-icon-wrap" @click="handleOpenPickerTo">
                    <input type="date" v-model="formData.date_of_expiry" class="date custom-icon" ref="exportToRef" />
                    <v-text-field
                      v-model="formData.date_of_expiry"
                      ref="input"
                      :placeholder="$t('idea_db_source.placeholder_db_date_of_expiry')"
                      class="text-input custom-icon-text mt-0"
                      onkeydown="false"
                      readonly
                      hide-details
                      dense
                    />
                  </div>
                </div>
              </div>
            </div>
          </v-col>

          <v-col cols="12">
            <div class="my-3">
              <div class="title-btn">
                <span>{{ $t('idea_db_source.label_db_file') }}</span>
                <div class="title-require">{{ $t('list_menu.label_essential') }}</div>
              </div>
              <div class="csv-file">
                <div
                  class="csv-input"
                  v-on:dragenter="dragEnter"
                  v-on:dragover="dragOver"
                  v-on:dragleave="dragLeave"
                  v-on:drop="dropEvent"
                >
                  <div class="import-csv">
                    <img :src="getIcon('upload')" alt="" class="upload-icon" />
                    <div class="csv-input-wrapper">
                      <input type="file" accept=".csv,.xlsx" id="csv" ref="csvInput" class="d-none" @change="handleAddFile" />
                      <div class="csv-input-text">{{ $t('list_menu.description_drag_and_drop_the_file_here') }}</div>
                    </div>
                  </div>
                  <common-button
                    class="button-import"
                    :label="$t('list_menu.button_select_files')"
                    type="colored"
                    @action="$refs.csvInput.click()"
                    v-ripple="false"
                  />
                </div>
                <div v-if="csvFilename !== ''" class="csv-filename">
                  <span class="csv-filename-detail">{{ csvFilename }}</span>
                  <div class="remove-icon">
                    <img class="remove-icon-detail" :src="getIcon('remove')" alt="" @click="handleDeleteFile" />
                  </div>
                </div>
              </div>
              <div class="csv-download">
                <div class="csv-description">{{ $t('idea_db_source.label_validate_file_size') }}</div>
              </div>
            </div>
          </v-col>

          <v-col>
            <div class="mt-3">
              <div class="title-btn title-btn-select-scope">
                <span>{{ $t('idea_db_source.label_db_note') }}</span>
              </div>
              <div class="listMenu-btn-text">
                <input-field
                  :label="$t('idea_db_source.placeholder_db_note')"
                  class="mt-2 mb-0"
                  v-model="formData.note"
                />
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="action-idea-source" ref="scrollElement">
        <div class="return__button-responsive" :class="{ 'fixed-button-bottom': isFixedButton }">
          <div class="registration-responsive">
            <common-button
              class="submit-register-btn"
              :label="$t('user_management.button_send')"
              :disabled="submitRegisterHandler()"
              type="colored"
              @action="redirectToNewRegisterData()"
              v-ripple="false"
            />
          </div>
        </div>
      </div>

      <notification-popup
        :dialog="dialogNotification"
        :message="$t('idea_db_source.popup_create_success')"
        @submit="navigateAfterSubmitSuccess"
      />

      <ErrorPopup :dialog="dialogLimit" isErrorImg :message="messageLimit" @submit="dialogLimit = false" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { ROUTES } from '@/router/constants';
import PageTitle from '@/components/pageTitle/pageTitle';
import CommonButton from '@/components/utils/button.vue';
import NotificationPopup from '@/components/dialogs/notification-popup';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import { createIdeaDbSource } from '@/api/idea-db';
import moment from 'moment';
import InputField from '@/components/products/input/InputField.vue';
import { validateDate } from '@/utils/validate';

export default {
  components: {
    PageTitle,
    CommonButton,
    NotificationPopup,
    ErrorPopup,
    InputField,
  },
  data() {
    return {
      breadcrumb: [
        {
          text: this.$t('list_menu.hyperlink_home'),
          disabled: false,
          href: ROUTES.HOME_DASHBOARD,
        },
        {
          text: this.$t('user_management.hyperlink_setting'),
          disabled: false,
          href: ROUTES.SETTING,
        },
        {
          text: this.$t('idea_db_source.hyperlink_db_source'),
          disabled: true,
          href: ROUTES.EMISSIONS,
        },
      ],

      formData: {
        data: '',
        db_name: '',
        version: '',
        identification_id: '',
        date_of_expiry: '',
        note: '',
      },

      dialogNotification: false,
      isLoading: false,

      messageLimit: this.$t('list_menu.description_message_limit'),
      csvFilename: '',
      csvFileData: '',
      dialogCsv: false,
      limitSize: false,
      isFixedButton: true,
      errorInputdate: '',
      dialogLimit: false,
      typeFileUpload: ['xlsx', 'csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'],
      typeFileDrop: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv']
    };
  },
  async mounted() {
    await Promise.all([this.updateBreadCrumb(this.breadcrumb)]);
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapState('userData', ['currentUser', 'language', 'existDbSource']),


    labelNotApplicable() {
      return this.$t('list_menu.label_not_applicable');
    },
  },

  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    handleScroll() {
      const scrollElementTop = this.$refs.scrollElement.offsetTop; // Vị trí của phần tử
      const scrollPosition = window.scrollY + window.innerHeight; // Vị trí scroll hiện tại

      if (scrollPosition > scrollElementTop + 235) {
        //position btn currently
        this.isFixedButton = false;
      } else {
        this.isFixedButton = true;
      }
    },

    navigateAfterSubmitSuccess() {
      const path = this.existDbSource ? ROUTES.LIST_IDEA_DB_SOURCE : ROUTES.SETTING
      this.$router.push({ path: path })
    },
    handleOpenPickerTo() {
      this.$refs.exportToRef.showPicker();
    },

    redirectToNewRegisterData() {
      if (this.isLoading) return;

      this.isLoading = true;
      let form_data = new FormData();
      Object.keys(this.formData).forEach((key) => form_data.append(key, this.formData[key]));

      createIdeaDbSource(form_data)
        .then((res) => {
          if (res.success) this.dialogNotification = true;
        })
        .catch((err) => {
          this.dialogLimit = true;
          this.messageLimit = this.$t('list_menu.description_message_notification');
        })
        .finally((_) => {
          this.isLoading = false;
        });
    },
    getSettingIcon(image) {
      return require(`@/assets/icons/${image}`);
    },

    submitRegisterHandler() {
      let validateForm = Object.keys(this.formData).filter((key) => (key != 'note' && this.formData[key]) === '');

      return (validateForm.length !== 0 && !this.validateDate) || this.isLoading;
    },
    getIcon(icon) {
      return require(`@/assets/icons/csv-input/${icon}.svg`);
    },
    dragEnter(event) {
      event.preventDefault();
    },
    dragOver(event) {
      event.preventDefault();
    },
    dragLeave() {},
    hasNonAsciiCharacters(inputText) {
      const nonAsciiRegex = /[^\x00-\x7F]/;
      return nonAsciiRegex.test(inputText);
    },
    handleAddFile(event) {
      if (event.target.files[0]) {
        const fileName = event.target.files[0].name;
        const fileSize = event.target.files[0].size;
        const fileType = event.target.files[0].type;

        if (!this.typeFileUpload.includes(fileType)) {
          this.limitSize = true;
          this.messageLimit = this.$t('idea_db_source.label_validate_file_size');
          this.dialogLimit = true;
          return;
        } else if (fileSize > 104857600) {
          this.limitSize = true;
          this.messageLimit = this.$t('idea_db_source.description_message_limit');
          this.dialogLimit = true;
          return;
        } else {
          this.csvFilename = fileName;
          const reader = new FileReader();
          this.formData.data = event.target.files[0];
          reader.readAsArrayBuffer(event.target.files[0]);
          this.$refs.csvInput.value = '';
        }
      }
    },
    async dropEvent(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];

      if (!this.typeFileDrop.includes(file.type)) {
        this.limitSize = true;
        this.messageLimit = this.$t('list_menu.description_limit');
        this.dialogLimit = true;
        return;
      } else if (file?.size > 104857600) {
        this.limitSize = true;
        this.messageLimit = this.$t('list_menu.description_message_limit');
        this.dialogLimit = true;
        return;
      } else {
        this.csvFilename = file.name;
        this.formData.data = event.dataTransfer.files[0];
        this.$refs.csvInput.value = '';
        // TODO: call api check csv file
      }
    },
    handleClosePopupCsv() {
      this.dialogCsv = false;
      this.$router.replace({ path: '/emissions/list-menu/?csv=true' });
    },
    handleDeleteFile() {
      this.csvFilename = '';
      this.csvFileData = '';
      this.formData.data = '';
    },
    changeDate() {
      this.errorInputdate = validateDate(this.formData.date_of_expiry);
    },
  },
};
</script>
<style lang="scss">
.listMenu-btn-text {
	.v-text-field.v-text-field--solo {
		.v-label {
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: 0.03em;
			color: $monoMid;
		}
		.v-input__slot {
			padding: 0 16px !important;
		}
	}
}

.v-list-item .v-list-item__title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: $monoBlack;
}

.select-type.select-item .v-select__selection {
  color: $monoBlack;
}

.select-type.select-item {
  @media (min-width: 1024px) {
    .v-select__selection {
      max-width: 440px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
<style lang="scss" scoped>
// position: fixed;
//     bottom: 0;
//     left: 236px;
//     margin-bottom: 0 !important;
.action-idea-source {
  height: 72px;
}
.return__button-responsive {
  &.fixed-button-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    top: auto;
  }
}

.form-name {
  color: $goldMid;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 1.6px;
}

.custom-table {
  @media (max-width: $tablet) {
    padding-left: 20px !important;
  }
}

.listMenu-page {
  .list-menu-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;

    width: 100%;

    // height: 40px;
    .return__header {
      display: none;
    }
  }

  .list-menu--title {
    width: 100%;
    // font-family: 'Source Han Sans JP';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    /* identical to box height, or 187% */
    margin-bottom: 48px;
    letter-spacing: 0.05em;

    /* Mono/Black */

    color: $monoBlack;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .listMenus-btn {
    display: flex;
    flex-flow: column;
    padding: 0px;
    /* Background/Light */
    background: #e3eded;
    // border-top-right-radius: 8px;
    // border-top-left-radius: 8px;
    border-radius: 8px;
    

    .listMenu-icon {
      justify-content: center;
      align-items: center;
      display: flex;
      line-height: 24px;
      padding: 24px;
    }

    .listMenu-btn {
      text-decoration: none;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      cursor: pointer;
      border-radius: 4px;
      margin-top: 10px;
      margin-bottom: 16px;

      // padding: 8px 8px 8px 12px;
      .listMenu-btn-text {
        width: 100%;
        align-items: center;

        .v-select {
          width: 100%;
        }
      }

      .select-year-month {
        display: flex;
        flex-flow: column !important;
        gap: 16px;
      }
    }

    .arrow-svg {
      width: 36px;
      height: 48px;
    }

    .title-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0px;
      margin: 0;
      gap: 16px;
      width: 100%;
      height: 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.42px;
      color: $monoBlack;

      .title-require {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 4px 2px;
        width: auto;
        height: 20px;

        background: $goldMid;
        border-radius: 4px;
        color: white;
        font-weight: 500;
        font-size: 11px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.03em;
      }
    }
  }

  .page-title {
    margin-bottom: 50px;
  }

  .v-select.v-text-field input {
    width: 496px;
    height: 40px;
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;

    .v-input__slot {
      border: 1px solid rgba(42, 42, 48, 0.1);
      border-radius: 4px;
      background: $monoOffWhite !important;
    }
  }

  .divider {
    width: 100%;
  }

  .list-menu-registration {
    display: none;
  }

  .title-btn-select-scope {
    margin-bottom: 12px;
  }

  .listMenus-btn {
    padding: 15px 20px;
  }

  .csv-file {
    background: #f7f7f2;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;
    margin-top: 10px;
  }

  .csv-input {
    background: $monoOffWhite;
    height: 252px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 16px;
    border-bottom: 1px solid rgba(42, 42, 48, 0.1);
    border-radius: 4px;
    .import-csv {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      height: 164px;
      width: 100%;
      border: 1px dashed #c8deea;
      padding: 32px 8px;
      gap: 8px;

      .upload-icon {
        width: 50px;
        height: 64px;
      }

      .csv-input-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .csv-input-text {
          font-weight: 400;
          font-size: 15px;
          line-height: 28px;
          text-align: center;
          letter-spacing: 0.75px;
          color: $monoMid;
        }
      }
    }
  }

  .csv-filename {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid $dividerBlack;

    &-detail {
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
      color: $monoBlack;
      //display: flex;
      //flex-direction: row;
      //align-items: center;
      padding: 0px 0px 0px 16px;
      word-break: break-all;
      //gap: 8px;
      height: inherit;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .remove-icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 8px;
      width: 60px;
      height: 60px;

      &-detail {
        cursor: pointer;
      }
    }
  }

  .csv-download {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    width: 100%;
    margin-top: 8px;
  }

  .csv-description {
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 0.75px;
    color: $monoBlack;
    font-weight: 400;
  }

  .csv-description-download {
    color: $goldMid;
    text-align: center;
    cursor: pointer;

    font-size: 15px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.75px;
    text-decoration-line: underline;
    position: relative;
    width: max-content;
    margin-inline: auto;

    .underline {
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 0;
      height: 1px;
      background-color: $goldMid;
      transition: width 0.3s ease;
      bottom: 2px;
    }

    &.disable {
      cursor: text;
      color: $monoBlack;
    }

    &:hover {
      text-decoration: underline;

      .underline {
        width: 100%;
      }
    }
  }
}

.return {
  &__button-responsive {
    width: calc(100% + 40px);
    display: flex;
    flex-direction: column;
    background: $bgMid;
    box-shadow: 0px -3.13px 2.86674px rgba(160, 181, 186, 0.165596), 0px -1.24px 1.13351px rgba(160, 181, 186, 0.24);
    position: relative;
    top: 80px;
    margin-left: -20px;
  }
}

.return__header-responsive {
  width: 100%;
  height: 40px;
  background: $monoOffWhite;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;

  .list-emission-return-responsive {
    height: 20px;
    padding-right: 8px;

    .return-active {
      display: none;
    }
  }

  &:hover {
    .list-emission-return-responsive {
      .return {
        display: none;
      }

      .return-active {
        display: block;
      }
    }
  }

  &:active {
    .list-emission-return-responsive {
      .return {
        display: block;
      }

      .return-active {
        display: none;
      }
    }
  }
}

.registration-responsive {
  width: 100%;
  margin: auto;
  padding: 16px 20px;

  .common-btn {
    width: 100%;
  }
}

@include desktop {
  .return__button-responsive {
    // display: none;
    max-width: calc(560px);
    margin: auto;
  }

  .fixed-button-bottom {
    left: 198px !important;
  }

  .input-elic {
    /* width: 440px; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 16px;
    margin-top: 8px;
    position: static;
    width: 496px;
    height: 40px;
    left: 0;
    top: 32px;
    background: $monoWhite;
    box-sizing: border-box;
    box-shadow: inset 0 2px 2px rgb(160 181 186 / 15%), inset 0 2px 6px rgb(160 181 186 / 50%);
    border-radius: 4px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    outline: none;
  }

  .listMenu-page {
    .listMenus-btn {
      display: flex;
      // flex-flow: row;
      flex-wrap: wrap;
      width: 100%;
      max-width: 560px;
      margin: auto;
      padding: 32px;

      .listMenu-btn {
        // margin: 0 12px 24px 12px;
        display: flex;
        flex-flow: column;
        // align-items: center;
        justify-content: center;
        margin-top: 10px;
        padding: unset;

        &:hover {
          cursor: pointer;
        }

        img.listMenu-icon {
          display: flex;
          width: 20px;
          height: 36px;
          transform: rotate(90deg);
          flex: none;
          order: 0;
          flex-grow: 0;
        }

        .input-title {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding: 0 16px;
          position: static;
          width: 496px !important;
          height: 40px;
          left: 0;
          top: 32px;
          background: $monoOffWhite;
          box-sizing: border-box;
          box-shadow: inset 0px 2px 2px rgb(160 181 186 / 15%), inset 0px 2px 6px rgb(160 181 186 / 50%);
          border-radius: 4px;
          flex: none;
          align-self: stretch;
          flex-grow: 0;
          margin: 8px 0px;
          outline: none;
        }

        .listMenu-btn-text {
          display: flex;
          flex-flow: column;

          // padding: 26px 0 22px;
          .listMenu-text {
            padding-left: 0;
          }
        }

        .listMenu-scope {
          /* Auto layout */
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 7px 16px 9px;

          width: 496px;
          height: 40px;

          /* Mono/OffWhite */

          background: $monoOffWhite;
          box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
            0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
            0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
          border-radius: 4px;

          /* Inside auto layout */
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }

        .select-year-month {
          flex-flow: row !important;
        }
      }
    }

    .submit-register-btn {
      // background: $goldMid !important;
      box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24),
        0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17),
        0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13),
        0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11),
        0px 36px 33px 0px rgba(160, 181, 186, 0.07);
      border-radius: 4px;
      transition: 0.1s ease-out;

      .v-btn__content {
        color: white;
      }
    }

    .list-menu-title {
      height: 40px;
      margin-bottom: 50px;
      width: 100%;
      padding-left: 40px;

      .page-title {
        margin-bottom: 0;
      }

      .return__header {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 !important;
        width: 161px;

        .list-emission-return {
          height: 20px;

          .return-active {
            display: none;
          }
        }

        &:hover {
          .list-emission-return {
            .return {
              display: none;
            }

            .return-active {
              display: block;
            }
          }
        }

        &:active {
          .list-emission-return {
            .return {
              display: block;
            }

            .return-active {
              display: none;
            }
          }
        }
      }
    }

    .list-menu--title {
      padding-left: 40px;
    }

    .list-menu-registration {
      width: 100%;
      max-width: calc(560px);
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 32px;
      gap: 16px;
      height: 104px;
      background: $bgLight;
      border-top: 1px solid rgba(121, 134, 134, 0.12);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      .common-btn {
        width: 100%;

        .v-btn__content {
          color: $monoWhite;
        }
      }
    }

    .csv-download {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0px;
      width: 100%;
      margin-top: 8px;
    }
  }
}

::v-deep .submit-register-btn {
  .v-btn__content {
    text-transform: capitalize !important;
  }
}

.v-application a {
  color: rgba(0, 0, 0, 0.87);
}

::v-deep .return__header,
::v-deep .return__button-responsive {
  .v-btn__content {
    div {
      text-transform: lowercase;
    }

    div:first-letter {
      text-transform: uppercase !important;
    }
  }
}
</style>
    
  